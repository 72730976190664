import React, { useContext } from 'react';
import ZendeskContext from '@/context/ZendeskContext';
import { deepCopy } from '@/util/state';
import Zendesk from 'react-zendesk';

const authorizedColorSchema = {
    theme: '#00141b',
    launcher: '#00adbb',
    launcherText: '#ffffff',
    button: '#00141b',
    resultLists: '#00141b',
    header: '#00141b',
    articleLinks: '#00141b'
};

const unauthorizedColorSchema = {
    // theme: '#00141b',
    theme: '#ffffff',
    // launcher: '#F5CE01',
    launcher: '#00adbb',
    launcherText: '#ffffff',
    button: '#00adbb',
    resultLists: '#ffffff',
    header: '#00141b',
    articleLinks: '#ffffff'
};

const authorizedPositionSchema = {
    horizontal: '0',
    vertical: '50px',
    mobile: {
        vertical: '-100px'
    }
};

const unauthorizedPositionSchema = {
    horizontal: '0',
    vertical: '50px',
    mobile: {
        vertical: '-100px'
    }
};

const ZendeskToggler = () => {
    const zendeskContext = useContext(ZendeskContext);

    // useEffect(() => {
    //   if (langRef.current) {
    //     window.zE('webWidget', 'setLocale', getZendeskLanguage(lang))
    //   }
    //   langRef.current = true
    // }, [lang])

    // useEffect(() => {
    //   const zendeskSnippet = document.getElementById('ze-snippet')
    //   if (zendeskSnippet) {
    //     scriptLoadedCallback(true as unknown as Event)
    //   }
    // }, [zendeskContext.isAuthorized])

    const loadSettings = () => ({
        webWidget: {
            zIndex: 9999,
            offset: zendeskContext.isAuthorized
                ? deepCopy(authorizedPositionSchema)
                : deepCopy(unauthorizedPositionSchema),
            color: zendeskContext.isAuthorized
                ? deepCopy(authorizedColorSchema)
                : deepCopy(unauthorizedColorSchema)
        },
        launcher: {
            chatLabel: {
                '*': 'Help'
            }
        }
    });

    return (
        <Zendesk
            defer
            zendeskKey="109d9683-6baa-49d5-bb0b-b93f0bc50a4f"
            onLoaded={() => {
                const zendeskSnippet = document.getElementsByTagName('iframe');
                console.log('Zendesk is loaded!', zendeskSnippet);
            }}
            {...loadSettings()}
        />
    );
};

// const getZendeskLanguage = (language: string) => {
//   const currentLanguage = language
//   let zendeskLanguage = supportedZendeskLanguages.find(langCode => langCode === currentLanguage)
//   if (!zendeskLanguage) zendeskLanguage = defaultZendeskLanguage
//   return zendeskLanguage
// }

export const openZendesk = (msg?: string) => {
    if (window.zE) {
        window.zE('webWidget', 'open');
        if (msg) window.zE('webWidget', 'chat:send', msg);
    } else {
        setTimeout(() => {
            if (window.zE) {
                window.zE('webWidget', 'open');
            }
        }, 1000);
    }
};

export default ZendeskToggler;
