import Head from "next/head";
import React from "react";

const MDHead = ({ specificPage }) => (
    <Head>
        <title>
            Crypto Dashboard
            {specificPage && ` - ${specificPage}`}
        </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
);

export default MDHead;
